export const projects = [
  {
    "title": "Cryptocurrency Information App",
    "subtitle": "Vite, React, TypeScript, Tailwind CSS, Netlify",
    "description": "A modern cryptocurrency tracking app providing real-time data on various cryptocurrencies. Features include interactive price history charts, and a dynamic UI developed with Tailwind CSS. The app fetches data from multiple APIs using RapidAPI and is continuously deployed on Netlify.",
    "image": "./crypto-info.png",
    "link": "https://crypto-information-app.netlify.app/",
    "gitlink": "https://github.com/connorflynn4/crypto-information",
    "hostlink": "https://crypto-information-app.netlify.app/"
  },
  {
    title: "AI Article Summarizer",
    subtitle: "React, Vite, Redux-Toolkit, Tailwind CSS, Local Storage",
    description:
      "This application is built using ReactJS and Vite, and it focuses on creating a responsive UI/UX with a glass morphism design using Tailwind CSS. It utilizes advanced RTK query API requests, which are conditionally triggered. The application also incorporates local storage for saving history, handles form events, catches errors, and implements a copy-to-clipboard functionality. Throughout the development process, emphasis is placed on writing clean and maintainable code.",
    image: "./ai_sum.png",
    link: "https://ai-article-summarizer-application.netlify.app/",
    gitlink: "https://github.com/connorflynn4/ai-summarizer",
    hostlink: "https://ai-article-summarizer-application.netlify.app/",
  },
  {
    title: "Coordinates Generator",
    subtitle: "HTML, CSS, JavaScript, Leaflet, Nominatim (OpenStreetMap)",
    description:
      "The Coordinates Generator is a web application that allows users to interactively select geographical points on a map, view their corresponding latitude and longitude values, and manage these coordinates efficiently.",
    image: "./coordgen.png",
    link: "https://connorflynn4.github.io/coordinates-generator/",
    gitlink: "https://github.com/connorflynn4/coordinates-generator",
    hostlink: "https://connorflynn4.github.io/coordinates-generator/",
  },
  
  {
    title: "AI Task Organizer",
    subtitle: "Next.js 13.4, GPT-4, Drag & Drop, Zustand, Appwrite Cloud, TS",
    description:
    "This application is built with Next.js 13.4 and Zustand, it aims to provide a task management solution. It incorporates GPT-4 for intelligent task insights and drag-and-drop features for easy task organization. It leverages Appwrite Cloud for secure backend services and it's developed using TypeScript, the app emphasizes clean, maintainable code for reliable task management",    
    image: "./ai_task_org.png",
    link: "https://ai-task-organizer.vercel.app/",
    gitlink: "https://github.com/connorflynn4/ai-task-organizer",
    hostlink: "https://ai-task-organizer.vercel.app/",
  },
  
  {
    title: "OMDB Movie App",
    subtitle: "React, React Router, React Hooks, Reactstrap, Node.js, OMDB API & Netlify",
    description:
      "The OMDB Movie Search App is a React-based web application that allows users to search for movies and view detailed information about each movie. Utilizing the OMDB API, users can search for movies by title and receive a list of matching results. Users can click on a 'View Movie' button to navigate to a detailed page that provides an in-depth overview of the selected movie, including its plot, genre, director, actors, and IMDB rating. Linked to GitHub and uses Netlify for continuous deployment",
    image: "./omdb-movie.png",
    link: "https://omdb-movie-app-portfolio.netlify.app/",
    gitlink: "https://github.com/connorflynn4/omdb-movie-app",
    hostlink: "https://omdb-movie-app-portfolio.netlify.app/",
  },
  {
    title: "Hulu Clone",
    subtitle: "React, Next.js, Tailwind CSS, Flexbox, CSS Grid & Vercel",
    description:
      "A Hulu clone React app that consumes the TMDb API, this app uses Next.js to implement server-side rendering. Users have the ability to filter through movies based on their category.",
    image: "./hulu-clone.png",
    link: "https://hulu-app-clone.vercel.app/",
    gitlink: "https://github.com/connorflynn4/hulu-clone",
    hostlink: "https://hulu-app-clone.vercel.app/",
  },

  

];



export const skills = [
  "Python (Flask, Scrapy, Selenium)",
  "JavaScript/TypeScript (Node, Express, React)",
  "C#",
  "HTML/CSS/SASS/Tailwind CSS",
  "Rest APIs",
  "Mapbox GL",
  "GPT-4",
  "Docker",
  "WordPress/Shopify",
  "Vercel, Heroku, Netlify",
  "Firebase, Mongo, SQL"
];
